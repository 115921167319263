import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.png";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
    
    <footer className="footer">
      <div class="section-divider"></div>
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">

              <a href="#"><img src={navIcon3} alt="Icon" /></a>
            </div>
            <div className="rights">@B.Z.</div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
